/* tslint:disable */
// This file was automatically generated and should not be edited.

export interface Query {
  quotation: (params: { id: ID }) => QuotationNode;

  allQuotation: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    company?: ID;
    customer_Name_Icontains?: String;
  }) => QuotationNodeConnection;

  request: (params: { id: ID }) => RequestNode;

  allRequests: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    currentLocation?: String;
    branchId?: ID;
    companyId?: ID;
  }) => RequestNodeConnection;

  state: (params: { id: ID }) => StateNode;

  allStates: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Iexact?: String;
    name_Icontains?: String;
    gstCode_Icontains?: String;
  }) => StateNodeConnection;

  district: (params: { id: ID }) => DistrictNode;

  allDistricts: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Icontains?: String;
  }) => DistrictNodeConnection;

  area: (params: { id: ID }) => AreaNode;

  allAreas: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Iexact?: String;
    name_Icontains?: String;
  }) => AreaNodeConnection;

  subarea: (params: { id: ID }) => SubareaNode;

  allSubarea: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Iexact?: String;
    pincode?: String;
  }) => SubareaNodeConnection;

  conversion: (params: { id: ID }) => ConversionNode;

  allConversions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    finishedProduct_Icontains?: String;
  }) => ConversionNodeConnection;

  conversionDetail: (params: { id: ID }) => ConversionDetailNode;

  allConversionDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  production: (params: { id: ID }) => ProductionNode;

  allProductions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => ProductionNodeConnection;

  productionTransfer: (params: { id: ID }) => ProductionTransferNode;

  allProductionTransfers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  directProduction: (params: { id: ID }) => DirectProductionNode;

  allDirectProductions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => DirectProductionNodeConnection;

  issue: (params: { id: ID }) => IssueNode;

  allIssues: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => IssueNodeConnection;

  remuneration: (params: { id: ID }) => RemunerationNode;

  allRemunerations: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    companyId?: ID;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
  }) => RemunerationNodeConnection;

  remunerationDetail: (params: { id: ID }) => RemunerationDetailNode;

  allRemunerationDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
    remunerationId?: ID;
    remuneration_Name?: String;
    remuneration_Name_Icontains?: String;
    remuneration_Name_Istartswith?: String;
  }) => RemunerationDetailNodeConnection;

  hsn: (params: { id: ID }) => HsnNode;

  allHsns: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    hsnCode_Icontains?: String;
    hsnCode?: String;
    companyId?: ID;
  }) => HsnNodeConnection;

  vendor: (params: { id: ID }) => VendorNode;

  allVendors: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    companyId?: ID;
  }) => VendorNodeConnection;

  openingStock: (params: { id: ID }) => OpeningStockNode;

  allOpeningStocks: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    productDetails_ProductId?: ID;
  }) => OpeningStockNodeConnection;

  approval: (params: { id: ID }) => ApprovalNode;

  allApprovals: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;

  gift: (params: { id: ID }) => GiftNode;

  allGifts: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => GiftNodeConnection;

  transfer: (params: { id: ID }) => TransferNode;

  allTransfers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  damage: (params: { id: ID }) => DamageNode;

  allDamages: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
  }) => DamageNodeConnection;

  jobOrder: (params: { id: ID }) => JobOrderNode;

  allJobOrders: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    jobOrderNo?: String;
    jobOrderNo_Icontains?: String;
    transferCompleted?: Boolean;
    companyId?: ID;
    id?: ID;
    transfers_ToVendor?: Boolean;
    transfers_ToLocation_Category_In?: String;
  }) => JobOrderNodeConnection;

  contractorTransfer: (params: { id: ID }) => ContractorTransferNode;

  allContractorTransfer: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;

  locationWiseTransfer: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
    limit: Int;
    offset: Int;
  }) => null;

  sales: (params: { id: ID }) => SalesNode;

  allSales: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  paymentMode: (params: { id: ID }) => PaymentModeNode;

  allPaymentModes: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    paymentType_Iexact?: String;
    sales_CompanyId?: ID;
    sales_BranchId?: ID;
    sales_Date_Lte?: Date;
    sales_Date_Gte?: Date;
    salechallan_CompanyId?: ID;
    salechallan_Date_Lte?: Date;
    salechallan_Date_Gte?: Date;
    paymentForCredit?: Boolean; // Ordering
    orderBy?: String;
  }) => PaymentModeNodeConnection;

  valueAddition: (params: { id: ID }) => ValueAdditionNode;

  allValueAdditions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Gte?: Date;
    date_Lte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    type_Iexact?: String;
  }) => ValueAdditionNodeConnection;

  deposit: (params: { id: ID }) => DepositNode;

  allDeposits: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => DepositNodeConnection;

  deliveryInstruction: (params: { id: ID }) => DeliveryInstructionNode;

  allDeliveryInstructions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    deliveryInstructionNo_Icontains?: String;
    id?: ID;
  }) => DeliveryInstructionNodeConnection;

  saleChallan: (params: { id: ID }) => SaleChallanNode;

  allSaleChallans: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    challanNo_Icontains?: String;
  }) => SaleChallanNodeConnection;

  dailySalesCentral: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => String;

  dailySalesBranch: (params: {
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  monthlyGstSalesReport: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  valueAdditionCentral: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => String;

  valueAdditionBranch: (params: {
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  totalServiceAmount: (params: {
    companyId: ID;
    branchId?: ID;
    startDate: Date;
    endDate: Date;
  }) => Float;

  paymentModeListBranch: (params: {
    companyId: ID;
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  paymentModeListCentral: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  creditReport: (params: {
    companyId: ID;
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  monthlySalesReport: (params: { companyId: ID; year: Int }) => null;

  cashBankReport: (params: {
    companyId: ID;
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  gstSlabWiseMonthlyReport: (params: {
    companyId: ID;
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  invoiceCreditReport: (params: {
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => null;

  masterData: (params: {
    companyId: ID;
    endDate: Date;
    limit: Int;
    offset: Int;
  }) => null;

  itemWiseDailySalesReport: (params: {
    companyId: ID;
    branchId: ID;
    endDate: Date;
    startDate: Date;
    limit: Int;
    offset: Int;
  }) => null;

  purchase: (params: { id: ID }) => PurchaseNode;

  allPurchases: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;

  purchaseOrder: (params: { id: ID }) => PurchaseOrderNode;

  allPurchaseOrders: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    purchaseOrderNo_Icontains?: String;
    purchaseOrderNo?: String;
    companyId?: ID;
  }) => PurchaseOrderNodeConnection;

  challan: (params: { id: ID }) => ChallanNode;

  allChallans: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    challanNo_Icontains?: String;
    challanNo?: String;
    vendorChallanNo_Icontains?: String;
    vendorChallanNo?: String;
    vendorChallanDate?: Date;
    vendorChallanDate_Lte?: Date;
    purchaseOrderId?: ID;
    companyId?: ID;
  }) => ChallanNodeConnection;

  margin: (params: { id: ID }) => MarginNode;

  allMargins: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    challanId?: ID;
    companyId?: ID;
  }) => MarginNodeConnection;

  marginListData: (params: { companyId: ID; count: Int; after: Int }) => null;

  unit: (params: { id: ID }) => UnitNode;

  allUnits: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
  }) => UnitNodeConnection;

  productCategory: (params: { id: ID }) => ProductCategoryNode;

  allProductCategories: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID; // Ordering
    orderBy?: String;
  }) => ProductCategoryNodeConnection;

  product: (params: { id: ID }) => ProductNode;

  allProducts: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
    categoryId?: ID;
    sellingRate_Lte?: Float;
    sellingRate_Gte?: Float;
    sellingRate?: Float;
    purchaseRate_Lte?: Float;
    purchaseRate_Gte?: Float;
    purchaseRate?: Float;
    productType_In?: String;
    barcodeBothContains?: String;
    barcodeBothExact?: String;
  }) => ProductNodeConnection;

  productDetails: (params: { id: ID }) => ProductDetailsNode;

  allProductDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementForId_Isnull?: Boolean;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
    date_Lte?: Date;
    date_Gte?: Date; // Ordering
    orderBy?: String;
  }) => ProductDetailsNodeConnection;

  itemWiseTotalStock: (params: {
    companyId: ID;
    date: Date;
    productId: ID;
  }) => null;

  vendorWiseTotalStock: (params: {
    companyId: ID;
    date: String;
    vendorId: ID;
  }) => null;

  itemWiseReceiptAndSalesStatus: (params: {
    companyId: ID;
    branchId: ID;
    startDate: Date;
    endDate: Date;
    categoryId: ID;
    limit: Int;
    offset: Int;
  }) => null;

  contractor: (params: { id: ID }) => ContractorNode;

  allContractor: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name_Icontains?: String;
    companyId?: ID;
  }) => ContractorNodeConnection;

  customer: (params: { id: ID }) => CustomerNode;

  allCustomers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Icontains?: String;
    name_Iexact?: String;
    contactNo?: String;
    contactNo_Icontains?: String;
    contactNo_Istartswith?: String;
    companyId?: ID;
    dateOfBirth_Contains?: String;
    dateOfAnniversary_Contains?: String;
    nameOrNumberIcontains?: String;
  }) => CustomerNodeConnection;

  company: (params: { id: ID }) => CompanyNode;

  allCompanies: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
  }) => CompanyNodeConnection;

  branch: (params: { id: ID }) => BranchNode;

  allBranches: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
    category?: String;
  }) => BranchNodeConnection;

  userDetail: (params: { id: ID }) => UserDetailNode;

  allUsers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
  }) => UserDetailNodeConnection;

  allGroups: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    id?: ID;
  }) => GroupNodeConnection;
}

export interface QuotationNode {
  id: ID;

  date: Date;

  customer: CustomerNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  company: CompanyNode;

  quotationNumber?: String;
}

export type ID = string;

export type Date = string;

export interface CustomerNode {
  id: ID;

  name: String;

  address?: String;

  contactNo?: String;

  email?: String;

  dateOfBirth?: String;

  dateOfAnniversary?: String;

  gstinNumber?: String;

  gstinLegalName?: String;

  pointBalance: Float;

  creditLimit?: Float;

  company: CompanyNode;

  salesSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  valueadditionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Gte?: Date;
    date_Lte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    type_Iexact?: String;
  }) => ValueAdditionNodeConnection;

  deliveryInstructions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    deliveryInstructionNo_Icontains?: String;
    id?: ID;
  }) => DeliveryInstructionNodeConnection;

  approvalSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;

  giftSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => GiftNodeConnection;

  quotation: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    company?: ID;
    customer_Name_Icontains?: String;
  }) => QuotationNodeConnection;

  lastPurchaseDetails?: LastPurchaseDetails;

  totalPurchaseDetails?: (params: {
    branchId?: ID;
    startDate: Date;
    endDate: Date;
  }) => TotalPurchaseDetails;
}

export type String = string;

export type Float = number;

export interface CompanyNode {
  id: ID;

  name: String;

  address?: String;

  state?: String;

  contactNo?: String;

  gstin: String;

  gstRate?: Float;

  hsnCode?: String;

  amountPerPointSale: Float;

  amountPerPointRedeem: Float;

  withProduction: Boolean;

  withAmc: Boolean;

  bankName?: String;

  branch?: String;

  accountNo?: String;

  ifscCode?: String;

  branches: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
    category?: String;
  }) => BranchNodeConnection;

  userdetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
  }) => UserDetailNodeConnection;

  customerSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => CustomerNodeConnection;

  hsnSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    hsnCode_Icontains?: String;
    hsnCode?: String;
    companyId?: ID;
  }) => HsnNodeConnection;

  vendorSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    companyId?: ID;
  }) => VendorNodeConnection;

  unitSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
  }) => UnitNodeConnection;

  productcategorySet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => ProductCategoryNodeConnection;

  productSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => ProductNodeConnection;

  productdetailsSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remunerationSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    companyId?: ID;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
  }) => RemunerationNodeConnection;

  remunerationdetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
    remunerationId?: ID;
    remuneration_Name?: String;
    remuneration_Name_Icontains?: String;
    remuneration_Name_Istartswith?: String;
  }) => RemunerationDetailNodeConnection;

  conversionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    finishedProduct_Icontains?: String;
  }) => ConversionNodeConnection;

  conversiondetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  productionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => ProductionNodeConnection;

  productiontransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  directproductionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => DirectProductionNodeConnection;

  issueSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => IssueNodeConnection;

  purchaseorderSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    purchaseOrderNo_Icontains?: String;
    purchaseOrderNo?: String;
    companyId?: ID;
  }) => PurchaseOrderNodeConnection;

  challanSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    challanNo_Icontains?: String;
    challanNo?: String;
    vendorChallanNo_Icontains?: String;
    vendorChallanNo?: String;
    vendorChallanDate?: Date;
    vendorChallanDate_Lte?: Date;
    purchaseOrderId?: ID;
    companyId?: ID;
  }) => ChallanNodeConnection;

  marginSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    challanId?: ID;
    companyId?: ID;
  }) => MarginNodeConnection;

  purchaseSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;

  salesSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  valueadditionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Gte?: Date;
    date_Lte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    type_Iexact?: String;
  }) => ValueAdditionNodeConnection;

  depositSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => DepositNodeConnection;

  deliveryInstructions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    deliveryInstructionNo_Icontains?: String;
    id?: ID;
  }) => DeliveryInstructionNodeConnection;

  salechallanSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    challanNo_Icontains?: String;
  }) => SaleChallanNodeConnection;

  contractorSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name_Icontains?: String;
    companyId?: ID;
  }) => ContractorNodeConnection;

  transferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  damageSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
  }) => DamageNodeConnection;

  joborderSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    jobOrderNo?: String;
    jobOrderNo_Icontains?: String;
    transferCompleted?: Boolean;
    companyId?: ID;
    id?: ID;
    transfers_ToVendor?: Boolean;
    transfers_ToLocation_Category_In?: String;
  }) => JobOrderNodeConnection;

  contractortransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;

  approvalSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;

  giftSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => GiftNodeConnection;

  openingstockSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    productDetails_ProductId?: ID;
  }) => OpeningStockNodeConnection;

  requestSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    currentLocation?: String;
    branchId?: ID;
    companyId?: ID;
  }) => RequestNodeConnection;

  quotation: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    company?: ID;
    customer_Name_Icontains?: String;
  }) => QuotationNodeConnection;
}

export type Boolean = boolean;

export interface BranchNodeConnection {
  pageInfo: PageInfo;

  edges: BranchNodeEdge[];
}

export interface PageInfo {
  hasNextPage: Boolean;

  hasPreviousPage: Boolean;

  startCursor?: String;

  endCursor: String;
}

export interface BranchNodeEdge {
  node?: BranchNode;

  cursor: String;
}

export interface BranchNode {
  id: ID;

  name: String;

  address: String;

  state?: String;

  contactNo: String;

  email?: String;

  gstin?: String;

  incharge?: String;

  category: BranchCategory;

  parentCategory?: BranchParentCategory;

  parentLocation?: BranchNode;

  company: CompanyNode;

  branchSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    companyId?: ID;
    category?: String;
  }) => BranchNodeConnection;

  userdetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
  }) => UserDetailNodeConnection;

  branchForSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  branchFromSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  sourceBranch: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  destinationBranch: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  productionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => ProductionNodeConnection;

  productionFromBranchSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  productionToBranchSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  challanSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    challanNo_Icontains?: String;
    challanNo?: String;
    vendorChallanNo_Icontains?: String;
    vendorChallanNo?: String;
    vendorChallanDate?: Date;
    vendorChallanDate_Lte?: Date;
    purchaseOrderId?: ID;
    companyId?: ID;
  }) => ChallanNodeConnection;

  salesSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  valueadditionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Gte?: Date;
    date_Lte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    type_Iexact?: String;
  }) => ValueAdditionNodeConnection;

  depositSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => DepositNodeConnection;

  fromLocationSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  toLocationSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  approvalSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;

  giftSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => GiftNodeConnection;

  openingstockSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    productDetails_ProductId?: ID;
  }) => OpeningStockNodeConnection;

  requestSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    currentLocation?: String;
    branchId?: ID;
    companyId?: ID;
  }) => RequestNodeConnection;

  companyId?: ID;

  companyName?: String;
}

export enum BranchCategory {
  // EMPORIUM
  EMPORIUM = 'EMPORIUM', // STOCK_POINT
  STOCK_POINT = 'STOCK_POINT', // REGIONAL_OFFICE
  REGIONAL_OFFICE = 'REGIONAL_OFFICE', // FINISHING_UNIT
  FINISHING_UNIT = 'FINISHING_UNIT', // CLUSTER
  CLUSTER = 'CLUSTER', // PWCS
  PWCS = 'PWCS', // SHGS
  SHGS = 'SHGS', // CFC
  CFC = 'CFC', // MELA
  MELA = 'MELA',
}

export enum BranchParentCategory {
  // EMPORIUM
  EMPORIUM = 'EMPORIUM', // STOCK_POINT
  STOCK_POINT = 'STOCK_POINT', // REGIONAL_OFFICE
  REGIONAL_OFFICE = 'REGIONAL_OFFICE', // FINISHING_UNIT
  FINISHING_UNIT = 'FINISHING_UNIT', // CLUSTER
  CLUSTER = 'CLUSTER', // PWCS
  PWCS = 'PWCS', // SHGS
  SHGS = 'SHGS', // CFC
  CFC = 'CFC', // MELA
  MELA = 'MELA',
}

export type Int = number;

export interface UserDetailNodeConnection {
  pageInfo: PageInfo;

  edges: UserDetailNodeEdge[];
}

export interface UserDetailNodeEdge {
  node?: UserDetailNode;

  cursor: String;
}

export interface UserDetailNode {
  id: ID;

  user: UserNode;

  branch?: BranchNode;

  company: CompanyNode;

  group?: GroupType;

  isSuperuser?: Boolean;
}

export interface UserNode {
  id: ID;

  password: String;

  lastLogin?: DateTime;

  isSuperuser: Boolean;

  groups: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    id?: ID;
  }) => GroupNodeConnection;

  userPermissions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => PermissionNodeConnection;

  username: String;

  firstName: String;

  lastName: String;

  email: String;

  isStaff: Boolean;

  isActive: Boolean;

  dateJoined: DateTime;

  userdetail?: UserDetailNode;
}

export type DateTime = string;

export interface GroupNodeConnection {
  pageInfo: PageInfo;

  edges: GroupNodeEdge[];
}

export interface GroupNodeEdge {
  node?: GroupNode;

  cursor: String;
}

export interface GroupNode {
  id: ID;

  name: String;

  permissions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => PermissionNodeConnection;

  userSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => UserNodeConnection;
}

export interface PermissionNodeConnection {
  pageInfo: PageInfo;

  edges: PermissionNodeEdge[];
}

export interface PermissionNodeEdge {
  node?: PermissionNode;

  cursor: String;
}

export interface PermissionNode {
  id: ID;

  name: String;

  codename: String;

  groupSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    name?: String;
    name_Icontains?: String;
    name_Istartswith?: String;
    id?: ID;
  }) => GroupNodeConnection;

  userSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => UserNodeConnection;
}

export interface UserNodeConnection {
  pageInfo: PageInfo;

  edges: UserNodeEdge[];
}

export interface UserNodeEdge {
  node?: UserNode;

  cursor: String;
}

export interface GroupType {
  id?: ID;

  name?: String;
}

export interface ProductDetailsNodeConnection {
  pageInfo: PageInfo;

  edges: ProductDetailsNodeEdge[];
}

export interface ProductDetailsNodeEdge {
  node?: ProductDetailsNode;

  cursor: String;
}

export interface ProductDetailsNode {
  id: ID;

  date?: Date;

  product?: ProductNode;

  newProduct?: ProductNode;

  rate: Float;

  purchaseRate: Float;

  gstRate?: Float;

  discount?: Float;

  quantity: Float;

  wastage?: Float;

  peduncleQuantity: Float;

  amount: Float;

  remark?: String;

  quantityForNewProduct?: Float;

  reed?: String;

  pick?: String;

  gsm?: String;

  movementType: ProductDetailsMovementType;

  movementFor?: BranchNode;

  movementFrom?: BranchNode;

  vendorFor?: VendorNode;

  status?: ProductDetailsStatus;

  company?: CompanyNode;

  area?: AreaNode;

  subarea?: SubareaNode;

  input: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    finishedProduct_Icontains?: String;
  }) => ConversionNodeConnection;

  directproductionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => DirectProductionNodeConnection;

  issueSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
  }) => IssueNodeConnection;

  purchaseSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;

  salesNewProductDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  salesOldProductDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  deliveryInstructions: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    deliveryInstructionNo_Icontains?: String;
    id?: ID;
  }) => DeliveryInstructionNodeConnection;

  saleChallans: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    challanNo_Icontains?: String;
  }) => SaleChallanNodeConnection;

  transferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  damageSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
  }) => DamageNodeConnection;

  contractortransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;

  approvalNewProductDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;

  giftSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => GiftNodeConnection;

  openingstockSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    productDetails_ProductId?: ID;
  }) => OpeningStockNodeConnection;

  quotation: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    company?: ID;
    customer_Name_Icontains?: String;
  }) => QuotationNodeConnection;

  availableQuantityForJobOrder: AvailableQuantityForJobOrder[];

  transferInvoiceNo: String;

  receivingInvoiceNo: String;
}

export interface PhysicalStockNode {
  physicalStock?: Float;
  availableStock?: Float;
  date?: Date;
}

export interface DiscountCategoryNode {
  id: ID;

  name: String;

  description?: String;

  mda: Float;

  commission: Float;

  company: CompanyNode;
}

export interface ProductNode {
  id: ID;

  category: ProductCategoryNode;

  oldBarcodeId?: String;

  newBarcodeId?: String;

  name: String;

  unit: UnitNode;

  colour?: String;

  purchaseRate: Float;

  sellingRate: Float;

  hsnCode?: String;

  hsn?: HsnNode;

  cgstRate?: Float;

  sgstRate?: Float;

  gstRate?: Float;

  division?: String;

  section?: String;

  materialType?: String;

  productType?: ProductProductType;

  reorderLevel?: Float;

  quantityPerPiece?: Float;

  company: CompanyNode;

  design?: String;

  dimension?: String;

  discountCategory: DiscountCategoryNode;

  oldProducts: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  newProducts: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  stock: (params: {
    branchId?: ID;
    companyId: ID;
    date: Date;
    fyStock?: Boolean;
  }) => StockReport;

  itemWiseSalesBranch: (params: {
    branchId?: ID;
    startDate: Date;
    endDate: Date;
  }) => ItemWiseSalesReportBranch;

  itemWiseSalesCentral: (params: { startDate: Date; endDate: Date }) => null;

  physicalStock: (params: {
    branchId?: ID;
    companyId: ID;
  }) => PhysicalStockNode;

  openingStock: (params: { branchId?: ID; companyId: ID }) => Float;

  newStock: (params: {
    branchId?: ID;
    companyId: ID;
    date: Date;
  }) => NewStockReport;

  stockDateRange: (params: {
    branchId?: ID;
    companyId: ID;
    startDate: Date;
    endDate: Date;
  }) => NewStockReport;
}

export interface ProductCategoryNode {
  id: ID;

  name: String;

  initials: String;

  company: CompanyNode;

  productSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => ProductNodeConnection;

  categoryStock: (params: {
    branchId?: ID;
    date: Date;
    companyId?: ID;
    fyStock?: Boolean;
  }) => StockReport;

  monthlySaleReport: (params: { branchId: ID; year: String }) => String;
}

export interface ProductNodeConnection {
  pageInfo: PageInfo;

  edges: ProductNodeEdge[];

  totalCount?: Int;
}

export interface ProductNodeEdge {
  node?: ProductNode;

  cursor: String;
}

export interface StockReport {
  openingStock?: Float;

  transfer?: Float;

  receiving?: Float;

  sale?: Float;

  saleReturn?: Float;

  saleCancel?: Float;

  saleChallan?: Float;

  approval?: Float;

  approvalReturn?: Float;

  damage?: Float;

  gift?: Float;

  purchase?: Float;

  purchaseReturn?: Float;

  conversion?: Float;

  codeConversion?: Float;

  totalStocks?: Float;

  mrp?: Float;

  totalStocksValue?: Float;

  purchaseRate?: Float;

  totalStocksPurchaseValue?: Float;

  name?: String;

  oldBarcode?: String;

  newBarcode?: String;

  reorderLevel?: Float;

  production?: Float;

  issue?: Float;

  inwardQty?: Float;

  inwardValue?: Float;

  outwardQty?: Float;

  outwardValue?: Float;

  openingStockValue?: Float;

  closingStockValue?: Float;
}

export interface NewStockReport extends StockReport {
  date: Date;
  physicalStockPresent: Boolean;
}

export interface UnitNode {
  id: ID;

  name: String;

  value: Float;

  company: CompanyNode;

  allowDecimal: boolean;

  productSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => ProductNodeConnection;
}

export interface HsnNode {
  id: ID;

  hsnCode: String;

  gst: Float;

  minValue: Float;

  maxValue: Float;

  company: CompanyNode;

  productSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
  }) => ProductNodeConnection;

  conversiondetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  hsnWithSameCode: HsnWithSameCode[];
}

export interface ConversionDetailNodeConnection {
  pageInfo: PageInfo;

  edges: ConversionDetailNodeEdge[];
}

export interface ConversionDetailNodeEdge {
  node?: ConversionDetailNode;

  cursor: String;
}

export interface ConversionDetailNode {
  id: ID;

  date: Date;

  conversion?: ConversionNode;

  quantity: Float;

  cost: Float;

  amount: Float;

  hsn?: HsnNode;

  gstRate?: Float;

  remark?: String;

  company: CompanyNode;

  sourceBranch?: BranchNode;

  destinationBranch?: BranchNode;

  conversionType: ConversionDetailConversionType;

  vendorFor?: VendorNode;

  status?: ConversionDetailStatus;

  productionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date_Gte?: Date;
    date_Lte?: Date;
    date?: Date;
    companyId?: ID;
    branchId?: ID;
  }) => ProductionNodeConnection;

  productiontransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  joborderSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    jobOrderNo?: String;
    jobOrderNo_Icontains?: String;
    transferCompleted?: Boolean;
    companyId?: ID;
    id?: ID;
    transfers_ToVendor?: Boolean;
    transfers_ToLocation_Category_In?: String;
  }) => JobOrderNodeConnection;

  quantityReceived?: Float;
}

export interface ConversionNode {
  id: ID;

  date: Date;

  productionType: ConversionProductionType;

  finishedProduct?: String;

  designCode?: String;

  oldDesignCode?: String;

  remark?: String;

  rawMaterials: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remunerations: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
    remunerationId?: ID;
    remuneration_Name?: String;
    remuneration_Name_Icontains?: String;
    remuneration_Name_Istartswith?: String;
  }) => RemunerationDetailNodeConnection;

  rawMaterialsCost?: Float;

  remunerationsCost?: Float;

  netCost?: Float;

  company: CompanyNode;

  image?: String;

  conversiondetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  stock: (params: {
    branchId: ID;
    startDate: Date;
    endDate: Date;
  }) => StockReportWithoutBarcode;
}

export enum ConversionProductionType {
  // HANDLOOM
  HANDLOOM = 'HANDLOOM', // HANDICRAFT
  HANDICRAFT = 'HANDICRAFT', // SERICULTURE
  SERICULTURE = 'SERICULTURE',
}

export interface RemunerationDetailNodeConnection {
  pageInfo: PageInfo;

  edges: RemunerationDetailNodeEdge[];
}

export interface RemunerationDetailNodeEdge {
  node?: RemunerationDetailNode;

  cursor: String;
}

export interface RemunerationDetailNode {
  id: ID;

  date?: Date;

  company: CompanyNode;

  remuneration?: RemunerationNode;

  quantity: Float;

  rate: Float;

  supervisionCharge: Float;

  amount: Float;

  remark?: String;

  conversionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    finishedProduct_Icontains?: String;
  }) => ConversionNodeConnection;
}

export interface RemunerationNode {
  id: ID;

  company: CompanyNode;

  name: String;

  remunerationdetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    companyId?: ID;
    remunerationId?: ID;
    remuneration_Name?: String;
    remuneration_Name_Icontains?: String;
    remuneration_Name_Istartswith?: String;
  }) => RemunerationDetailNodeConnection;
}

export interface ConversionNodeConnection {
  pageInfo: PageInfo;

  edges: ConversionNodeEdge[];

  totalCount?: Int;
}

export interface ConversionNodeEdge {
  node?: ConversionNode;

  cursor: String;
}

export interface StockReportWithoutBarcode {
  product?: String;

  production?: Float;

  transfer?: Float;

  receiving?: Float;
}

export enum ConversionDetailConversionType {
  // PRODUCTION
  PRODUCTION = 'PRODUCTION', // TRANSFER
  TRANSFER = 'TRANSFER', // JOB_ORDER
  JOB_ORDER = 'JOB_ORDER',
}

export interface VendorNode {
  id: ID;

  name: String;

  address?: String;

  state?: String;

  contactPersonName?: String;

  contactNo: String;

  email?: String;

  gstin?: String;

  company: CompanyNode;

  productdetailsSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  conversiondetailSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  productiontransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  purchaseorderSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    purchaseOrderNo_Icontains?: String;
    purchaseOrderNo?: String;
    companyId?: ID;
  }) => PurchaseOrderNodeConnection;

  purchaseSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;

  valueadditionSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Gte?: Date;
    date_Lte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    type_Iexact?: String;
  }) => ValueAdditionNodeConnection;

  transferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;
}

export interface ProductionTransferNodeConnection {
  pageInfo: PageInfo;

  edges: ProductionTransferNodeEdge[];
}

export interface ProductionTransferNodeEdge {
  node?: ProductionTransferNode;

  cursor: String;
}

export interface ProductionTransferNode {
  id: ID;

  date: Date;

  toHeadOffice?: Boolean;

  fromBranch?: BranchNode;

  toBranch?: BranchNode;

  conversionDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  transferInvoiceNo?: String;

  receivingInvoiceNo?: String;

  remarks?: String;

  status: ProductionTransferStatus;

  receivingDate?: Date;

  jobOrder?: JobOrderNode;

  fromVendor: Boolean;

  toVendor: Boolean;

  vendorFor?: VendorNode;

  company?: CompanyNode;

  marginSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    challanId?: ID;
    companyId?: ID;
  }) => MarginNodeConnection;

  hsnGstWiseDetails: HsnGstWiseDetails[];

  hsnGstWiseDetailsReceiving: HsnGstWiseDetails[];

  productDetailsWithMargin: ProductDetailsWithMargin;
}

export enum ProductionTransferStatus {
  // PENDING
  PENDING = 'PENDING', // REJECTED
  REJECTED = 'REJECTED', // ACCEPTED
  ACCEPTED = 'ACCEPTED', // PARTIALLY_ACCEPTED
  PARTIALLY_ACCEPTED = 'PARTIALLY_ACCEPTED',
}

export interface JobOrderNode {
  id: ID;

  date?: Date;

  orderFor: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  jobOrderNo: String;

  transferCompleted: Boolean;

  company: CompanyNode;

  partialCancel: boolean;

  productionTransfers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    fromBranchId?: ID;
    toBranchId?: ID;
    companyId?: ID;
    toHeadOffice?: Boolean;
    status?: String;
    status_Contains?: String;
    conversionDetails_Status?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
  }) => ProductionTransferNodeConnection;

  transfers: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;

  firstTransferId?: ID;
}

export interface TransferNodeConnection {
  pageInfo: PageInfo;

  edges: TransferNodeEdge[];
}

export interface TransferNodeEdge {
  node?: TransferNode;

  cursor: String;
}

export interface TransferNode {
  id: ID;

  date: Date;

  fromHeadOffice?: Boolean;

  toHeadOffice?: Boolean;

  fromLocation?: BranchNode;

  toLocation?: BranchNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  transferInvoiceNo?: String;

  receivingInvoiceNo?: String;

  isTransfer: Boolean;

  remarks?: String;

  status: TransferStatus;

  receivingDate?: Date;

  jobOrder?: JobOrderNode;

  fromVendor: Boolean;

  toVendor: Boolean;

  vendorFor?: VendorNode;

  margin?: MarginNode;

  company?: CompanyNode;

  hsnGstWiseAmount: HsnGstWiseAmount[];

  hsnGstWiseAmountReceiving: HsnGstWiseAmount[];

  commissionDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    category?: String;
    description?: String;
  }) => CommissionDetailsNodeConnection;
}

export enum TransferStatus {
  // PENDING
  PENDING = 'PENDING', // REJECTED
  REJECTED = 'REJECTED', // ACCEPTED
  ACCEPTED = 'ACCEPTED', // PARTIALLY_ACCEPTED
  PARTIALLY_ACCEPTED = 'PARTIALLY_ACCEPTED', // DISABLED
  DISABLED = 'DISABLED',
}

export interface CommissionDetailsNodeConnection {
  pageInfo: PageInfo;

  edges: CommissionDetailsNodeEdge[];
}

export interface CommissionDetailsNodeEdge {
  node?: CommissionDetailsNode;

  cursor: String;
}

export interface CommissionDetailsNode {
  id?: ID;

  category: String;

  description?: String;

  descriptionPercent?: Float;

  descriptionValue: Float;

  transferSet?: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    isTransfer?: Boolean;
    fromHeadOffice?: Boolean;
    toHeadOffice?: Boolean;
    fromLocationId?: ID;
    fromLocationId_Isnull?: Boolean;
    toLocationId?: ID;
    toLocationId_Isnull?: Boolean;
    companyId?: ID;
    status?: String;
    status_Contains?: String;
    status_In?: String;
    productDetails_Status?: String;
    jobOrderId_Isnull?: Boolean;
    jobOrder_JobOrderNo?: String;
    jobOrder_JobOrderNo_Icontains?: String;
    transferInvoiceNo?: String;
    transferInvoiceNo_Icontains?: String;
    toVendor?: Boolean;
  }) => TransferNodeConnection;
}

export interface MarginNode {
  id: ID;

  itemsFrom: MarginItemsFrom;

  challan?: ChallanNode;

  productionTransfer: ProductionTransferNode;

  freight: Float;

  otherExpenses: Float;

  productDetails: String;

  company: CompanyNode;

  purchaseSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;

  transfer?: TransferNode;
}

export enum MarginItemsFrom {
  // PURCHASE
  PURCHASE = 'PURCHASE', // PRODUCTION
  PRODUCTION = 'PRODUCTION',
}

export interface ChallanNode {
  id: ID;

  date: Date;

  challanNo: String;

  productDetails: String;

  purchaseOrder?: PurchaseOrderNode;

  vendorChallanNo: String;

  vendorChallanDate: Date;

  dumpToHeadOffice: Boolean;

  dumpLocation?: BranchNode;

  remarks?: String;

  company: CompanyNode;

  marginSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    challanId?: ID;
    companyId?: ID;
  }) => MarginNodeConnection;

  productDetailsWithMargin: ProductDetailsWithMargin;
}

export interface PurchaseOrderNode {
  id: ID;

  date: Date;

  purchaseOrderNo: String;

  vendor?: VendorNode;

  productDetails: String;

  remarks?: String;

  company: CompanyNode;

  shippingAddress?: String;

  quotationDetails?: String;

  isCancel?: Boolean;

  challanSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    challanNo_Icontains?: String;
    challanNo?: String;
    vendorChallanNo_Icontains?: String;
    vendorChallanNo?: String;
    vendorChallanDate?: Date;
    vendorChallanDate_Lte?: Date;
    purchaseOrderId?: ID;
    companyId?: ID;
  }) => ChallanNodeConnection;

  productDetailsWithAvailbleQty?: ProductDetailsWithAvailableQty;
}

export interface ChallanNodeConnection {
  pageInfo: PageInfo;

  edges: ChallanNodeEdge[];
}

export interface ChallanNodeEdge {
  node?: ChallanNode;

  cursor: String;
}

export interface ProductDetailsWithAvailableQty {
  productDetails?: String;
}

export interface MarginNodeConnection {
  pageInfo: PageInfo;

  edges: MarginNodeEdge[];
}

export interface MarginNodeEdge {
  node?: MarginNode;

  cursor: String;
}

export interface ProductDetailsWithMargin {
  productDetails: String;
}

export interface PurchaseNodeConnection {
  pageInfo: PageInfo;

  edges: PurchaseNodeEdge[];
}

export interface PurchaseNodeEdge {
  node?: PurchaseNode;

  cursor: String;
}

export interface PurchaseNode {
  id: ID;

  date: Date;

  margin?: MarginNode;

  billNo: String;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  vendor?: VendorNode;

  vendorInvoiceNo?: String;

  vendorInvoiceDate?: Date;

  purchaseOrderNo?: String;

  purchaseOrderDate?: Date;

  receivingChallanNo?: String;

  packingCharges?: Float;

  transportationCharges?: Float;

  otherCharges?: Float;

  netAmount?: Float;

  isReturn: Boolean;

  purchaseToReturn?: PurchaseNode;

  remarks?: String;

  company: CompanyNode;

  purchaseSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    vendorId?: ID;
    productDetails_Product_Name?: String;
    productDetails_Product_Name_Icontains?: String;
    productDetails_Product_Name_Istartswith?: String;
    isReturn?: Boolean;
    billNo_Icontains?: String;
    billNo?: String;
  }) => PurchaseNodeConnection;
}

export interface HsnGstWiseAmount {
  hsnCode?: String;

  gst?: Float;

  quantity?: Float;

  amount?: Float;
}

export interface HsnGstWiseDetails {
  hsnCode?: String;

  gst?: Float;

  quantity?: Float;

  amount?: Float;
}

export interface PurchaseOrderNodeConnection {
  pageInfo: PageInfo;

  edges: PurchaseOrderNodeEdge[];
}

export interface PurchaseOrderNodeEdge {
  node?: PurchaseOrderNode;

  cursor: String;
}

export interface ValueAdditionNodeConnection {
  pageInfo: PageInfo;

  edges: ValueAdditionNodeEdge[];
}

export interface ValueAdditionNodeEdge {
  node?: ValueAdditionNode;

  cursor: String;
}

export interface ValueAdditionNode {
  id: ID;

  date: Date;

  customer?: CustomerNode;

  vendor?: VendorNode;

  remarks: String;

  amount: Float;

  billNo: String;

  branchBillNo: Int;

  type: ValueAdditionType;

  branch: BranchNode;

  company: CompanyNode;
}

export enum ValueAdditionType {
  // PAYMENT
  PAYMENT = 'PAYMENT', // RECEIPT
  RECEIPT = 'RECEIPT',
}

export enum ConversionDetailStatus {
  // ACCEPTED
  ACCEPTED = 'ACCEPTED', // REJECTED
  REJECTED = 'REJECTED', // PENDING
  PENDING = 'PENDING',
}

export interface ProductionNodeConnection {
  pageInfo: PageInfo;

  edges: ProductionNodeEdge[];
}

export interface ProductionNodeEdge {
  node?: ProductionNode;

  cursor: String;
}

export interface ProductionNode {
  id: ID;

  date: Date;

  billNo?: String;

  conversionDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    sourceBranchId?: ID;
    status?: String;
    conversionType?: String;
    conversion_FinishedProduct_Icontains?: String;
  }) => ConversionDetailNodeConnection;

  amount?: Float;

  remark?: String;

  company?: CompanyNode;

  branch?: BranchNode;
}

export interface JobOrderNodeConnection {
  pageInfo: PageInfo;

  edges: JobOrderNodeEdge[];
}

export interface JobOrderNodeEdge {
  node?: JobOrderNode;

  cursor: String;
}

export interface HsnWithSameCode {
  hsnCode?: String;

  gst?: Float;

  minValue?: Float;

  maxValue?: Float;
}

export enum ProductProductType {
  // FINISHED
  FINISHED = 'FINISHED', // SEMI_FINISHED
  SEMI_FINISHED = 'SEMI_FINISHED', // RAW_MATERIAL
  RAW_MATERIAL = 'RAW_MATERIAL',
  GRAMUDYOG = 'GRAMUDYOG',
  WOOLLEN = 'WOOLLEN',
  FABRIC = 'FABRIC',
  READYMADE = 'READYMADE',
  BLANKET = 'BLANKET',
}

export interface ItemWiseSalesReportBranch {
  productName?: String;

  oldBarcodeId?: String;

  newBarcodeId?: String;

  categoryName?: String;

  quantity?: Float;

  sellingRate?: Float;

  amount?: Float;
}

export interface ItemWiseSalesReportCentral {
  branchName?: String;

  productName?: String;

  quantity?: Float;

  totalQuantity?: Float;

  totalAmount?: Float;
}

export interface ItemWisePurchaseReport {
  quantity?: Float;

  amount?: Float;
}

export enum ProductDetailsMovementType {
  // UP
  UP = 'UP', // DOWN
  DOWN = 'DOWN', // SALE
  SALE = 'SALE', // PURCHASE
  PURCHASE = 'PURCHASE', // DAMAGE
  DAMAGE = 'DAMAGE', // PURCHASE_RETURN
  PURCHASE_RETURN = 'PURCHASE_RETURN', // EXCHANGE
  EXCHANGE = 'EXCHANGE', // APPROVAL
  APPROVAL = 'APPROVAL', // APPROVAL_RETURN
  APPROVAL_RETURN = 'APPROVAL_RETURN', // OPENING_STOCK
  OPENING_STOCK = 'OPENING_STOCK', // CONVERSION
  CONVERSION = 'CONVERSION', // GIFT
  GIFT = 'GIFT', // SALE_CANCEL
  SALE_CANCEL = 'SALE_CANCEL', // EXCHANGE_CANCEL
  EXCHANGE_CANCEL = 'EXCHANGE_CANCEL', // REQUEST
  REQUEST = 'REQUEST', // CODE_CONVERSION
  CODE_CONVERSION = 'CODE_CONVERSION', // ITEM_CONVERSION
  ITEM_CONVERSION = 'ITEM_CONVERSION', // CONTRACTOR_TRANSFER
  CONTRACTOR_TRANSFER = 'CONTRACTOR_TRANSFER', // DELIVERY_INSTRUCTION
  DELIVERY_INSTRUCTION = 'DELIVERY_INSTRUCTION', // SALE_CHALLAN
  SALE_CHALLAN = 'SALE_CHALLAN', // SALE_CHALLAN_CANCEL
  SALE_CHALLAN_CANCEL = 'SALE_CHALLAN_CANCEL', // PRODUCTION
  PRODUCTION = 'PRODUCTION', // ISSUE
  ISSUE = 'ISSUE', // QUOTATION
  QUOTATION = 'QUOTATION',
}

export enum ProductDetailsStatus {
  // ACCEPTED
  ACCEPTED = 'ACCEPTED', // REJECTED
  REJECTED = 'REJECTED', // PENDING
  PENDING = 'PENDING', // DISABLED
  DISABLED = 'DISABLED',
}

export interface AreaNode {
  id: ID;

  name: String;

  district?: DistrictNode;

  productdetailsSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  subareaSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Iexact?: String;
    pincode?: String;
  }) => SubareaNodeConnection;

  contractortransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;
}

export interface DistrictNode {
  id: ID;

  name: String;

  state?: StateNode;

  areaSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Iexact?: String;
    name_Icontains?: String;
  }) => AreaNodeConnection;
}

export interface StateNode {
  id: ID;

  name: String;

  gstCode?: String;

  districtSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    id?: ID;
    name_Icontains?: String;
  }) => DistrictNodeConnection;
}

export interface DistrictNodeConnection {
  pageInfo: PageInfo;

  edges: DistrictNodeEdge[];
}

export interface DistrictNodeEdge {
  node?: DistrictNode;

  cursor: String;
}

export interface AreaNodeConnection {
  pageInfo: PageInfo;

  edges: AreaNodeEdge[];
}

export interface AreaNodeEdge {
  node?: AreaNode;

  cursor: String;
}

export interface SubareaNodeConnection {
  pageInfo: PageInfo;

  edges: SubareaNodeEdge[];
}

export interface SubareaNodeEdge {
  node?: SubareaNode;

  cursor: String;
}

export interface SubareaNode {
  id: ID;

  name: String;

  pincode?: String;

  area?: AreaNode;

  productdetailsSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  contractortransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;
}

export interface ContractorTransferNodeConnection {
  pageInfo: PageInfo;

  edges: ContractorTransferNodeEdge[];
}

export interface ContractorTransferNodeEdge {
  node?: ContractorTransferNode;

  cursor: String;
}

export interface ContractorTransferNode {
  id: ID;

  date: Date;

  company: CompanyNode;

  contractor: ContractorNode;

  area: AreaNode;

  subarea: SubareaNode;

  invoiceNo: String;

  remarks?: String;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;
}

export interface ContractorNode {
  id: ID;

  name: String;

  address?: String;

  contactNo?: String;

  email?: String;

  tdsRate: Float;

  serviceCharge: Float;

  company: CompanyNode;

  contractortransferSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    areaId?: ID;
    subareaId?: ID;
    companyId?: ID;
    contractorId?: ID;
    invoiceNo?: String;
    invoiceNo_Icontains?: String;
  }) => ContractorTransferNodeConnection;
}

export interface DirectProductionNodeConnection {
  pageInfo: PageInfo;

  edges: DirectProductionNodeEdge[];
}

export interface DirectProductionNodeEdge {
  node?: DirectProductionNode;

  cursor: String;
}

export interface DirectProductionNode {
  id: ID;

  date: Date;

  billNo: String;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remarks?: String;

  company: CompanyNode;
}

export interface IssueNodeConnection {
  pageInfo: PageInfo;

  edges: IssueNodeEdge[];
}

export interface IssueNodeEdge {
  node?: IssueNode;

  cursor: String;
}

export interface IssueNode {
  id: ID;

  date: Date;

  billNo: String;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remarks?: String;

  company: CompanyNode;
}

export interface SalesNodeConnection {
  pageInfo: PageInfo;

  edges: SalesNodeEdge[];
}

export interface SalesNodeEdge {
  node?: SalesNode;

  cursor: String;
}

export interface SalesNode {
  id: ID;

  date: Date;

  customer?: CustomerNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  billNo: String;

  branchBillNo?: String;

  billAmount: Float;

  discount: Float;

  redeemablePoint: Float;

  toRedeem: Boolean;

  redeemValue: Float;

  grossAmount: Float;

  cgst: Float;

  sgst: Float;

  netAmount: Float;

  totalAmount?: Float;

  paymentModes: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    paymentType_Iexact?: String;
    sales_CompanyId?: ID;
    sales_BranchId?: ID;
    sales_Date_Lte?: Date;
    sales_Date_Gte?: Date;
    paymentForCredit?: Boolean;
  }) => PaymentModeNodeConnection;

  customerPaid?: Float;

  balanceRefund?: Float;

  chequeNumber?: String;

  bankName?: String;

  gstinLegalName?: String;

  gstinNumber?: String;

  remarks?: String;

  isValueAddition: Boolean;

  valueAdditionRemarks?: String;

  valueAdditionAmount?: Float;

  isExchange: Boolean;

  oldBillNo?: String;

  oldProductDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  cancelled: Boolean;

  saleBy?: String;

  billBy?: String;

  company: CompanyNode;

  branch?: BranchNode;
}

export interface PaymentModeNodeConnection {
  pageInfo: PageInfo;

  edges: PaymentModeNodeEdge[];
}

export interface PaymentModeNodeEdge {
  node?: PaymentModeNode;

  cursor: String;
}

export interface PaymentModeNode {
  id: ID;

  date?: Date;

  paymentType: String;

  amount: Float;

  paymentForCredit: Boolean;

  narration?: String;

  balanceAmount?: Float;

  salesSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    billNo_Icontains?: String;
    companyId?: ID;
    branchId?: ID;
    paymentModes_PaymentType_Iexact?: String;
  }) => SalesNodeConnection;

  salechallanSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    challanNo_Icontains?: String;
  }) => SaleChallanNodeConnection;
}

export interface SaleChallanNodeConnection {
  pageInfo: PageInfo;

  edges: SaleChallanNodeEdge[];
}

export interface SaleChallanNodeEdge {
  node?: SaleChallanNode;

  cursor: String;
}

export interface SaleChallanNode {
  id: ID;

  date: Date;

  challanNo: String;

  deliveryInstruction?: DeliveryInstructionNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remarks?: String;

  dispatchedThrough?: String;

  destination?: String;

  vehicleNo?: String;

  miscellaneousCharge: Float;

  transportationCharges: Float;

  otherCharges: Float;

  totalAmount: Float;

  paymentModes: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    paymentType_Iexact?: String;
    sales_CompanyId?: ID;
    sales_BranchId?: ID;
    sales_Date_Lte?: Date;
    sales_Date_Gte?: Date;
    paymentForCredit?: Boolean;
  }) => PaymentModeNodeConnection;

  cancelled: Boolean;

  company: CompanyNode;

  hsnGstWiseAmount?: HsnGstWiseAmount[];
}

export interface DeliveryInstructionNode {
  id: ID;

  date: Date;

  deliveryInstructionNo: String;

  customer: CustomerNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  remarks?: String;

  purchaseOrderNumber?: String;

  purchaseOrderDate?: Date;

  layingAndFittingCharge: Float;

  transportationCharge: Float;

  otherCharges: Float;

  isGstInclusive: Boolean;

  company: CompanyNode;

  saleChallans: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    date_Gte?: Date;
    companyId?: ID;
    challanNo_Icontains?: String;
  }) => SaleChallanNodeConnection;

  saleChallanProductDetails: SaleChallanProductDetails[];
}

export interface SaleChallanProductDetails {
  productId?: ID;

  product?: ProductNode;

  orderQuantity?: Float;

  rate?: Float;

  purchaseRate?: Float;

  gstRate?: Float;

  dispatchedQuantity?: Float;

  remainingQuantity?: Float;

  quantity?: Float;

  movementType?: MOVEMENT_TYPE;
}

export enum MOVEMENT_TYPE {
  UP = 'UP',
  DOWN = 'DOWN',
  SALE = 'SALE',
  PURCHASE = 'PURCHASE',
  DAMAGE = 'DAMAGE',
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  EXCHANGE = 'EXCHANGE',
  APPROVAL = 'APPROVAL',
  APPROVAL_RETURN = 'APPROVAL_RETURN',
  OPENING_STOCK = 'OPENING_STOCK',
  CONVERSION = 'CONVERSION',
  GIFT = 'GIFT',
  SALE_CANCEL = 'SALE_CANCEL',
  EXCHANGE_CANCEL = 'EXCHANGE_CANCEL',
  REQUEST = 'REQUEST',
  CODE_CONVERSION = 'CODE_CONVERSION',
  ITEM_CONVERSION = 'ITEM_CONVERSION',
  CONTRACTOR_TRANSFER = 'CONTRACTOR_TRANSFER',
  DELIVERY_INSTRUCTION = 'DELIVERY_INSTRUCTION',
  SALE_CHALLAN = 'SALE_CHALLAN',
  SALE_CHALLAN_CANCEL = 'SALE_CHALLAN_CANCEL',
  PRODUCTION = 'PRODUCTION',
  ISSUE = 'ISSUE',
  QUOTATION = 'QUOTATION',
}

export interface DeliveryInstructionNodeConnection {
  pageInfo: PageInfo;

  edges: DeliveryInstructionNodeEdge[];
}

export interface DeliveryInstructionNodeEdge {
  node?: DeliveryInstructionNode;

  cursor: String;
}

export interface DamageNodeConnection {
  pageInfo: PageInfo;

  edges: DamageNodeEdge[];
}

export interface DamageNodeEdge {
  node?: DamageNode;

  cursor: String;
}

export interface DamageNode {
  id: ID;

  date: Date;

  remarks?: String;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  company: CompanyNode;
}

export interface ApprovalNodeConnection {
  pageInfo: PageInfo;

  edges: ApprovalNodeEdge[];
}

export interface ApprovalNodeEdge {
  node?: ApprovalNode;

  cursor: String;
}

export interface ApprovalNode {
  id: ID;

  date: Date;

  customer?: CustomerNode;

  isReturn: Boolean;

  oldApproval?: ApprovalNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  approvalNo: String;

  branchSerialNo?: String;

  totalCostAmount?: Float;

  totalMrp: Float;

  approvedByDesignation: String;

  approvedByName: String;

  company: CompanyNode;

  branch?: BranchNode;

  approvalSet: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    date?: Date;
    date_Lte?: Date;
    companyId?: ID;
    branchId?: ID;
    branchId_Isnull?: Boolean;
  }) => ApprovalNodeConnection;
}

export interface GiftNodeConnection {
  pageInfo: PageInfo;

  edges: GiftNodeEdge[];
}

export interface GiftNodeEdge {
  node?: GiftNode;

  cursor: String;
}

export interface GiftNode {
  id: ID;

  date: Date;

  customer?: CustomerNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  giftReceiptNo: String;

  branchSerialNo?: String;

  totalCostAmount: Float;

  totalMrp: Float;

  orderByDesignation: String;

  orderByName: String;

  company: CompanyNode;

  branch: BranchNode;
}

export interface OpeningStockNodeConnection {
  pageInfo: PageInfo;

  edges: OpeningStockNodeEdge[];
}

export interface OpeningStockNodeEdge {
  node?: OpeningStockNode;

  cursor: String;
}

export interface OpeningStockNode {
  id: ID;

  company: CompanyNode;

  branch?: BranchNode;

  productDetails: (params: {
    before?: String;
    after?: String;
    first?: Int;
    last?: Int;
    product_Name?: String;
    product_Name_Icontains?: String;
    product_Name_Istartswith?: String;
    companyId?: ID;
    movementForId?: ID;
    movementType?: String;
    transfer_ToHeadOffice?: Boolean;
    status?: String;
    status_In?: String;
  }) => ProductDetailsNodeConnection;

  date: Date;
}

export interface QuotationNodeConnection {
  pageInfo: PageInfo;

  edges: QuotationNodeEdge[];
}

export interface QuotationNodeEdge {
  node?: QuotationNode;

  cursor: String;
}

export interface AvailableQuantityForJobOrder {
  jobOrderId?: String;

  quantity?: Float;
}

export interface DepositNodeConnection {
  pageInfo: PageInfo;

  edges: DepositNodeEdge[];
}

export interface DepositNodeEdge {
  node?: DepositNode;

  cursor: String;
}

export interface DepositNode {
  id: ID;

  date: Date;

  depositAmount: Float;

  image?: String;

  remarks?: String;

  branch: BranchNode;

  company: CompanyNode;
}

export interface RequestNodeConnection {
  pageInfo: PageInfo;

  edges: RequestNodeEdge[];
}

export interface RequestNodeEdge {
  node?: RequestNode;

  cursor: String;
}

export interface RequestNode {
  id: ID;

  date: Date;

  detail: String;

  currentLocation: RequestCurrentLocation;

  isAvailable?: Boolean;

  branch?: BranchNode;

  company: CompanyNode;
}

export enum RequestCurrentLocation {
  // MARKETING
  MARKETING = 'MARKETING', // STORE
  STORE = 'STORE', // PRODUCTION
  PRODUCTION = 'PRODUCTION',
}

export interface CustomerNodeConnection {
  pageInfo: PageInfo;

  edges: CustomerNodeEdge[];
}

export interface CustomerNodeEdge {
  node?: CustomerNode;

  cursor: String;
}

export interface HsnNodeConnection {
  pageInfo: PageInfo;

  edges: HsnNodeEdge[];
}

export interface HsnNodeEdge {
  node?: HsnNode;

  cursor: String;
}

export interface VendorNodeConnection {
  pageInfo: PageInfo;

  edges: VendorNodeEdge[];
}

export interface VendorNodeEdge {
  node?: VendorNode;

  cursor: String;
}

export interface UnitNodeConnection {
  pageInfo: PageInfo;

  edges: UnitNodeEdge[];
}

export interface UnitNodeEdge {
  node?: UnitNode;

  cursor: String;
}

export interface ProductCategoryNodeConnection {
  pageInfo: PageInfo;

  edges: ProductCategoryNodeEdge[];
}

export interface ProductCategoryNodeEdge {
  node?: ProductCategoryNode;

  cursor: String;
}

export interface RemunerationNodeConnection {
  pageInfo: PageInfo;

  edges: RemunerationNodeEdge[];
}

export interface RemunerationNodeEdge {
  node?: RemunerationNode;

  cursor: String;
}

export interface ContractorNodeConnection {
  pageInfo: PageInfo;

  edges: ContractorNodeEdge[];
}

export interface ContractorNodeEdge {
  node?: ContractorNode;

  cursor: String;
}

export interface LastPurchaseDetails {
  lastPurchasedFrom?: String;

  lastPurchasedAt?: Date;
}

export interface TotalPurchaseDetails {
  amount: Float;
}

export interface StateNodeConnection {
  pageInfo: PageInfo;

  edges: StateNodeEdge[];
}

export interface StateNodeEdge {
  node?: StateNode;

  cursor: String;
}

export interface LocationWiseTransfer {
  date?: Date;

  branchName?: String;

  itemName?: String;

  quantity?: Float;
}

export interface DailySalesBranch {
  date?: String;

  billNo?: String;

  grossAmount?: Float;

  totalAmount?: Float;

  cgst?: Float;

  sgst?: Float;

  netAmount?: Float;

  exchangeAmount?: Float;

  valueAdditionAmount?: Float;

  paymentMode?: String;

  creditAmountReceived?: Float;
}

export interface MonthlyGSTSalesReport {
  branch?: String;

  grossAmount?: Float;

  cgst?: Float;

  sgst?: Float;

  netAmount?: Float;

  exchangeAmount?: Float;

  totalAmount?: Float;
}

export interface ValueAdditionBranch {
  date?: String;

  billNo?: String;

  remarks?: String;

  amount?: Float;
}

export interface PaymentModeReportBranch {
  date?: String;

  cash?: Float;

  card?: Float;

  credit?: Float;

  cheque?: Float;

  bank?: Float;

  cod?: Float;

  cancelSales?: Float;

  totalForDate?: Float;
}

export interface PaymentModeReportCentral {
  branch?: String;

  cash?: Float;

  card?: Float;

  credit?: Float;

  cheque?: Float;

  bank?: Float;

  cod?: Float;

  cancelSales?: Float;

  totalForBranch?: Float;
}

export interface CreditReport {
  id?: ID;

  billDate?: Date;

  billNo?: String;

  customerName?: String;

  netAmount?: Float;

  creditAmount?: Float;
}

export interface GstSlabWiseMonthlyReport {
  hsnCode?: String;

  gst?: Float;

  gstValue?: Float;

  cgstValue?: Float;

  sgstValue?: Float;

  igstValue?: Float;
}

export interface InvoiceCreditReport {
  id?: ID;

  billDate?: Date;

  billNo?: String;

  customerName?: String;

  netAmount?: Float;

  creditAmount?: Float;
}

export interface MasterData {
  branch?: String;

  branchGstin?: string;

  billNo?: String;

  date?: Date;

  productId?: ID;

  productName?: String;

  barcode?: String;

  customerName?: String;

  costPrice?: Float;

  mrp?: Float;

  grossSaleValue?: Float;

  netSaleValue?: Float;

  totalDiscountValue?: Float;

  discountPercent?: Float;

  category?: String;

  material?: String;

  quantity?: Float;

  division?: String;

  hsnCode?: String;

  gstPercent: Float;

  gstValue?: Float;

  cgst?: Float;

  sgst?: Float;

  taxableValue?: Float;

  vendorName?: String;

  remarks?: String;

  unit?: String;

  gstinNumber?: String;
}

export interface SummarizedReceivingReport {
  jobOrderDate?: Date;

  jobOrderNo?: String;

  productName?: String;

  vendor?: String;

  productionCenter?: String;

  orderedQty?: Float;

  receivedQty?: Float;

  costPrice?: Float;

  wapNo?: String;
}

export interface ItemWiseDailySalesReportData {
  date?: Date;

  billNo?: String;

  productName?: String;

  barcodeOld?: String;

  barcodeNew?: String;

  categoryName?: String;

  unit?: String;

  hsnCode?: String;

  quantity?: Int;

  mrp?: Float;

  netAmount?: Float;

  gross?: Float;

  discount?: Float;

  gst?: Float;

  taxableValue?: Float;

  payableGst?: Float;

  cgst?: Float;

  sgst?: Float;
}

export interface MarginListData {
  id?: Int;

  itemFrom?: String;

  product?: String;

  rate?: Float;

  quantity?: Float;

  amount?: Float;

  totalPerQuantity?: Float;

  margin?: Float;

  mrp?: Float;

  barcode?: String;
}

export interface ItemWiseTotalStock {
  branchName?: String;

  quantity?: String;

  mrp?: String;

  purchaseRate?: String;

  totalStocksValue?: String;

  totalStocksPurchaseValue?: String;
}

export interface VendorWiseTotalStock {
  productBarCode?: String;

  stock?: Int;

  name?: String;

  mrp?: Float;

  totalPurchase?: Float;

  totalSale?: Float;

  purchaseRate?: Float;

  purchaseValue?: Float;

  stockValue?: Float;
}

export interface ItemWiseReceiptAndSalesStatus {
  id?: Int;

  itemName?: String;

  date?: Date;

  qtyReceived?: Float;

  costPrice?: Float;

  mrp?: Float;

  qtySold?: Float;
}

export interface ItemWisePurchase {
  grcNo: String;
  grcDate: Date;
  vendorName: String;
  itemName: String;
  oldBarcode: String;
  newBarcode: String;
  quantity: Float;
  purchaseCose: Float;
  value: Float;
  purchaseOrderNo: String;
  purchaseOrderDate: Date;
}

export interface ProductIssue {
  jobOrderNo: String;
  date: Date;
  location: String;
  productName: String;
  oldBarcode: String;
  newBarcode: String;
  category: String;
  division: String;
  vendorName: String;
  costPrice: Float;
  quantity: Float;
  costValue: Float;
}

export interface CompanyNodeConnection {
  pageInfo: PageInfo;

  edges: CompanyNodeEdge[];
}

export interface CompanyNodeEdge {
  node?: CompanyNode;

  cursor: String;
}

export interface Mutation {
  createQuotation: (params: {
    input: CreateQuotationInput;
  }) => CreateQuotationPayload;

  createRequest: (params: {
    input: CreateRequestInput;
  }) => CreateRequestPayload;

  createConversion: (params: {
    input: CreateConversionInput;
  }) => CreateConversionPayload;

  createProduction: (params: {
    input: CreateProductionInput;
  }) => CreateProductionPayload;

  createProductionTransfer: (params: {
    input: CreateProductionTransferInput;
  }) => CreateProductionTransferPayload;

  createDirectProduction: (params: {
    input: CreateDirectProductionInput;
  }) => CreateDirectProductionPayload;

  createIssue: (params: { input: CreateIssueInput }) => CreateIssuePayload;

  createRemuneration: (params: {
    input: CreateRemunerationInput;
  }) => CreateRemunerationPayload;

  createRemunerationDatail: (params: {
    input: CreateRemunerationDetailInput;
  }) => CreateRemunerationDetailPayload;

  createHsn: (params: { input: CreateHsnInput }) => CreateHsnPayload;

  createVendor: (params: { input: CreateVendorInput }) => CreateVendorPayload;

  createOpeningStock: (params: {
    input: CreateOpeningStockInput;
  }) => CreateOpeningStockPayload;

  createState: (params: { input: CreateStateInput }) => CreateStatePayload;

  createDistrict: (params: {
    input: CreateDistrictInput;
  }) => CreateDistrictPayload;

  createArea: (params: { input: CreateAreaInput }) => CreateAreaPayload;

  createSubarea: (params: {
    input: CreateSubareaInput;
  }) => CreateSubareaPayload;

  createApproval: (params: {
    input: CreateApprovalInput;
  }) => CreateApprovalPayload;

  createGift: (params: { input: CreateGiftInput }) => CreateGiftPayload;

  createTransfer: (params: {
    input: CreateTransferInput;
  }) => CreateTransferPayload;

  createDamage: (params: { input: CreateDamageInput }) => CreateDamagePayload;

  createJobOrder: (params: {
    input: CreateJobOrderInput;
  }) => CreateJobOrderPayload;

  createContractorTransfer: (params: {
    input: CreateContractorTransferInput;
  }) => CreateContractorTransferPayload;

  deleteTransfer: (params: {
    input: DeleteTransferInput;
  }) => DeleteTransferPayload;

  createSales: (params: { input: CreateSalesInput }) => CreateSalesPayload;

  updatePaymentModeForSales: (params: {
    input: UpdatePaymentModeForSalesInput;
  }) => UpdatePaymentModeForSalesPayload;

  createValueAddition: (params: {
    input: CreateValueAdditionInput;
  }) => CreateValueAdditionPayload;

  createDeposit: (params: {
    input: CreateDepositInput;
  }) => CreateDepositPayload;

  createDeliveryInstruction: (params: {
    input: CreateDeliveryInstructionInput;
  }) => CreateDeliveryInstructionPayload;

  createSaleChallan: (params: {
    input: CreateSaleChallanInput;
  }) => CreateSaleChallanPayload;

  createPurchase: (params: {
    input: CreatePurchaseInput;
  }) => CreatePurchasePayload;

  createPurchaseOrder: (params: {
    input: CreatePurchaseOrderInput;
  }) => CreatePurchaseOrderPayload;

  createChallan: (params: {
    input: CreateChallanInput;
  }) => CreateChallanPayload;

  createMargin: (params: { input: CreateMarginInput }) => CreateMarginPayload;

  createUnit: (params: { input: CreateUnitInput }) => CreateUnitPayload;

  createProductCategory: (params: {
    input: CreateProductCategoryInput;
  }) => CreateProductCategoryPayload;

  createProduct: (params: {
    input: CreateProductInput;
  }) => CreateProductPayload;

  createProductDetails: (params: {
    input: CreateProductDetailsInput;
  }) => CreateProductDetailsPayload;

  createContractor: (params: {
    input: CreateContractorInput;
  }) => CreateContractorPayload;

  createCustomer: (params: {
    input: CreateCustomerInput;
  }) => CreateCustomerPayload;

  createCompany: (params: {
    input: CreateCompanyInput;
  }) => CreateCompanyPayload;

  createBranch: (params: { input: CreateBranchInput }) => CreateBranchPayload;

  createUser: (params: {
    input: CreateUserDetailInput;
  }) => CreateUserDetailPayload;
}

export interface CreateQuotationPayload {
  newQuotation?: QuotationNode;

  clientMutationId?: String;
}
export interface CreateQuotationInput {
  quotation?: QuotationInput;
  clientMutationId?: String;
}
export interface QuotationInput {
  id?: ID;
  date: Date;
  customerId: ID | null;
  productDetails: ProductDetailsInput[];
  companyId?: ID | null;
}
export interface ProductDetailsInput {
  id?: String;
  date?: Date | null;
  productId: ID | null;
  newProductId?: ID | null;
  rate: Float | null;
  purchaseRate: Float | null;
  gstRate?: Float | null;
  discount?: Float;
  quantity: Float | null;
  wastage?: Float;
  peduncleQuantity?: Float;
  amount: Float | null;
  movementType: String;
  remark?: String | null;
  reed?: String;
  pick?: String;
  gsm?: String;
  status?: String;
  quantityForNewProduct?: Float;
  movementForId?: ID | null;
  movementFromId?: ID | null;
  vendorForId?: ID | null;
  companyId?: ID | null;
}
export interface CreateRequestPayload {
  newRequest?: RequestNode;

  clientMutationId?: String;
}
export interface CreateRequestInput {
  request?: RequestInput;
  clientMutationId?: String;
}
export interface RequestInput {
  id?: ID;
  date: Date;
  detail: String;
  currentLocation?: String;
  isAvailable?: Boolean | null;
  branchId?: ID | null;
  companyId?: ID | null;
}
export interface CreateConversionPayload {
  newConversion?: ConversionNode;

  clientMutationId?: String;
}
export interface CreateConversionInput {
  conversion?: ConversionInput;
  clientMutationId?: String;
}
export interface ConversionInput {
  id?: ID;
  date?: Date;
  remark?: String;
  designCode?: String | null;
  oldDesignCode?: String;
  productionType?: String;
  finishedProduct?: String;
  rawMaterials?: ProductDetailsInput[];
  remunerations?: RemunerationDetailInput[];
  rawMaterialsCost?: Float;
  remunerationsCost?: Float;
  netCost?: Float;
  companyId?: ID;
  image?: String;
}
export interface RemunerationDetailInput {
  id?: ID;
  date?: Date | null;
  remunerationId?: ID;
  companyId?: ID | null;
  quantity?: Float | null;
  rate?: Float | null;
  remark?: String | null;
  supervisionCharge?: Float | null;
  amount?: Float | null;
}
export interface CreateProductionPayload {
  newProduction?: ProductionNode;

  clientMutationId?: String;
}
export interface CreateProductionInput {
  production?: ProductionInput;
  clientMutationId?: String;
}
export interface ProductionInput {
  id?: ID;
  date: Date;
  billNo?: String;
  remark?: String;
  conversionDetails: ConversionDetailInput[];
  amount: Float;
  companyId?: ID;
  branchId?: ID;
}
export interface ConversionDetailInput {
  id?: ID;
  date?: Date | null;
  conversionId?: ID | null;
  quantity?: Float | null;
  cost?: Float | null;
  amount?: Float | null;
  hsnId?: ID | null;
  gstRate?: Float | null;
  remark?: String | null;
  companyId?: ID | null;
  sourceBranchId?: ID | null;
  destinationBranchId?: ID | null;
  conversionType?: String;
  vendorForId?: ID | null;
  status?: String;
}
export interface CreateProductionTransferPayload {
  newProductionTransfer?: ProductionTransferNode;

  clientMutationId?: String;
}
export interface CreateProductionTransferInput {
  productionTransfer?: ProductionTransferInput;
  clientMutationId?: String;
}
export interface ProductionTransferInput {
  id?: ID;
  date: Date;
  toHeadOffice: Boolean;
  fromBranchId?: ID | null;
  toBranchId?: ID | null;
  conversionDetails: ConversionDetailInput[];
  transferInvoiceNo?: String | null;
  receivingInvoiceNo?: String;
  remarks?: String;
  status?: String;
  receivingDate?: Date | null;
  jobOrderId?: ID | null;
  fromVendor: Boolean;
  toVendor: Boolean;
  vendorForId?: ID | null;
  companyId?: ID | null;
}
export interface CreateDirectProductionPayload {
  newDirectProduction?: DirectProductionNode;

  clientMutationId?: String;
}
export interface CreateDirectProductionInput {
  production?: DirectProductionInput;
  clientMutationId?: String;
}
export interface DirectProductionInput {
  id?: ID;
  date: Date;
  billNo?: String;
  remarks?: String;
  productDetails: ProductDetailsInput[];
  companyId?: ID;
}
export interface CreateIssuePayload {
  newIssue?: IssueNode;

  clientMutationId?: String;
}
export interface CreateIssueInput {
  issue?: IssueInput;
  clientMutationId?: String;
}
export interface IssueInput {
  id?: ID;
  date: Date;
  billNo?: String;
  remarks?: String;
  productDetails: ProductDetailsInput[];
  companyId?: ID;
}
export interface CreateRemunerationPayload {
  newRemuneration?: RemunerationNode;

  clientMutationId?: String;
}
export interface CreateRemunerationInput {
  remuneration?: RemunerationInput;
  clientMutationId?: String;
}
export interface RemunerationInput {
  id?: ID;
  name: String;
  companyId?: ID | null;
}
export interface CreateRemunerationDetailPayload {
  newRemunerationDetail?: RemunerationDetailNode;

  clientMutationId?: String;
}
export interface CreateRemunerationDetailInput {
  remunerationDatail?: RemunerationDetailInput;
  clientMutationId?: String;
}
export interface CreateHsnPayload {
  newHsn?: HsnNode;

  clientMutationId?: String;
}
export interface CreateHsnInput {
  hsn?: HsnInput;
  clientMutationId?: String;
}
export interface HsnInput {
  id?: ID;
  hsnCode: String;
  gst: Float;
  minValue: Float;
  maxValue: Float;
  companyId?: ID | null;
}
export interface CreateVendorPayload {
  newVendor?: VendorNode;

  clientMutationId?: String;
}
export interface CreateVendorInput {
  vendor?: VendorInput;
  clientMutationId?: String;
}
export interface VendorInput {
  id?: ID;
  name: String;
  address?: String;
  state?: String;
  contactPersonName: String;
  contactNo: String;
  email?: String;
  gstin?: String;
  companyId: ID;
}
export interface CreateOpeningStockPayload {
  newOpeningStock?: OpeningStockNode;

  clientMutationId?: String;
}
export interface CreateOpeningStockInput {
  openingStock?: OpeningStockInput;
  clientMutationId?: String;
}
export interface OpeningStockInput {
  id?: ID;
  companyId: ID;
  branchId?: ID;
  productDetails: ProductDetailsInput[];
  date: Date;
}
export interface CreateStatePayload {
  newState?: StateNode;

  clientMutationId?: String;
}
export interface CreateStateInput {
  state?: StateInput;
  clientMutationId?: String;
}
export interface StateInput {
  id?: ID;
  name: String;
  gstCode?: String;
}
export interface CreateDistrictPayload {
  newDistrict?: DistrictNode;

  clientMutationId?: String;
}
export interface CreateDistrictInput {
  district?: DistrictInput;
  clientMutationId?: String;
}
export interface DistrictInput {
  id?: ID;
  name: String;
  stateId: ID;
}
export interface CreateAreaPayload {
  newArea?: AreaNode;

  clientMutationId?: String;
}
export interface CreateAreaInput {
  area?: AreaInput;
  clientMutationId?: String;
}
export interface AreaInput {
  id?: ID;
  name: String;
  districtId: ID;
}
export interface CreateSubareaPayload {
  newSubarea?: SubareaNode;

  clientMutationId?: String;
}
export interface CreateSubareaInput {
  subarea?: SubareaInput;
  clientMutationId?: String;
}
export interface SubareaInput {
  id?: ID;
  name: String;
  pincode?: String;
  areaId: ID;
}
export interface CreateApprovalPayload {
  newApproval?: ApprovalNode;

  clientMutationId?: String;
}
export interface CreateApprovalInput {
  approval?: ApprovalInput;
  clientMutationId?: String;
}
export interface ApprovalInput {
  id?: ID;
  date: Date;
  customerId?: ID;
  isReturn: Boolean;
  oldApprovalId?: ID;
  productDetails: ProductDetailsInput[];
  approvalNo?: String;
  branchSerialNo?: String;
  totalCostAmount: Float;
  totalMrp: Float;
  approvedByDesignation: String;
  approvedByName: String;
  companyId: ID;
  branchId?: ID;
}
export interface CreateGiftPayload {
  newGift?: GiftNode;

  clientMutationId?: String;
}
export interface CreateGiftInput {
  gift?: GiftInput;
  clientMutationId?: String;
}
export interface GiftInput {
  id?: ID;
  date: Date;
  customerId?: ID;
  productDetails: ProductDetailsInput[];
  giftReceiptNo?: String;
  branchSerialNo?: String;
  totalCostAmount: Float;
  totalMrp: Float;
  orderByDesignation: String;
  orderByName: String;
  companyId: ID;
  branchId: ID;
}
export interface CreateTransferPayload {
  newTransfer?: TransferNode;

  clientMutationId?: String;
}
export interface CreateTransferInput {
  transfer?: TransferInput;
  clientMutationId?: String;
}
export interface TransferInput {
  id?: ID;
  date: Date;
  fromHeadOffice: Boolean;
  toHeadOffice: Boolean;
  fromLocationId?: ID | null;
  toLocationId?: ID | null;
  productDetails?: ProductDetailsInput[];
  transferInvoiceNo?: String | null;
  receivingInvoiceNo?: String;
  isTransfer: Boolean;
  remarks?: String | null;
  status: String;
  receivingDate?: Date;
  jobOrderId?: ID;
  fromVendor: Boolean;
  toVendor: Boolean;
  vendorForId?: ID | null;
  companyId?: ID | null;
}
export interface CreateDamagePayload {
  newDamage?: DamageNode;

  clientMutationId?: String;
}
export interface CreateDamageInput {
  damage?: DamageInput;
  clientMutationId?: String;
}
export interface DamageInput {
  id?: ID;
  date: Date;
  remarks?: String;
  productDetails: ProductDetailsInput[];
  companyId: ID;
}
export interface CreateJobOrderPayload {
  newJobOrder?: JobOrderNode;

  clientMutationId?: String;
}
export interface CreateJobOrderInput {
  jobOrder?: JobOrderInput;
  clientMutationId?: String;
}
export interface JobOrderInput {
  id?: ID;
  continuation: Boolean;
  oldJobOrderId?: ID | null;
  date?: Date;
  orderFor: ConversionDetailInput[];
  jobOrderNo?: String;
  transferCompleted: Boolean;
  companyId?: ID | null;
  transferData: TransferInput;
}
export interface CancelJobOrderInput {
  id: ID;
  clientMutationId?: String;
}
export interface CancelJobOrderPayload {
  jobOrderToCancel?: JobOrderNode;

  clientMutationId?: String;
}
export interface JobOrderPartialCancelInput {
  id?: ID;
  jobOrderId: ID;
  vendorInvoiceNo: String;
  vendorDate: Date;
  tranferData: TransferInput;
  companyId: ID;
}
export interface CreateJobOrderPartialCancelInput {
  jobOrderPartialCancel: JobOrderPartialCancelInput;
  clientMutationId?: String;
}
export interface JobOrderPartialCancelNode {
  id: ID;
  jobOrder: JobOrderNode;
  vendorInvoiceNo: String;
  vendorDate: Date;
  transfer: TransferNode;
  remarks: String;
  compnay: CompanyNode;
}
export interface JobOrderPartialCancelNodeEdge {
  node?: JobOrderPartialCancelNode;
  cursor: String;
}
export interface JobOrderPartialCancelNodeConnection {
  pageInfo: PageInfo;
  edges: JobOrderPartialCancelNodeEdge[];
}
export interface CreateJobOrderPartialCancelPayload {
  newJobOrderPartialCancelNode: JobOrderPartialCancelNode;
  clientMutationId: String;
}
export interface CreateContractorTransferPayload {
  newContractorTransfer?: ContractorTransferNode;

  clientMutationId?: String;
}
export interface CreateContractorTransferInput {
  contractorTransfer?: ContractorTransferInput;
  clientMutationId?: String;
}
export interface ContractorTransferInput {
  id?: ID;
  date: Date;
  companyId?: ID;
  contractorId?: ID;
  areaId?: ID;
  subareaId?: ID;
  Remarks?: String;
  productDetailId?: ID;
  invoiceNo?: String;
}
export interface DeleteTransferPayload {
  transferToDelete?: TransferNode;

  clientMutationId?: String;
}
export interface DeleteTransferInput {
  id: ID;
  clientMutationId?: String;
}
export interface CreateSalesPayload {
  newSales?: SalesNode;

  clientMutationId?: String;
}
export interface CreateSalesInput {
  sales?: SalesInput;
  clientMutationId?: String;
}
export interface SalesInput {
  id?: ID;
  date: Date;
  customerId?: ID;
  productDetails: ProductDetailsInput[];
  billNo: String;
  branchBillNo?: String;
  billAmount: Float;
  discount: Float;
  redeemablePoint: Float;
  toRedeem: Boolean;
  redeemValue: Float;
  grossAmount: Float;
  cgst: Float;
  sgst: Float;
  netAmount: Float;
  totalAmount?: Float;
  customerPaid?: Float;
  balanceRefund?: Float;
  paymentModes: PaymentModeInput[];
  chequeNumber?: String;
  bankName?: String;
  gstinLegalName?: String;
  gstinNumber?: String;
  remarks?: String;
  isValueAddition: Boolean;
  valueAdditionRemarks?: String;
  valueAdditionAmount?: Float;
  isExchange: Boolean;
  oldBillNo?: String;
  oldProductDetails: ProductDetailsInput[];
  cancelled?: Boolean;
  saleBy?: String;
  billBy?: String;
  companyId: ID;
  branchId: ID;
}
export interface PaymentModeInput {
  id?: ID;
  date: Date;
  paymentType: String;
  amount: Float;
}
export interface UpdatePaymentModeForSalesPayload {
  newPaymentMode?: PaymentModeNode;

  clientMutationId?: String;
}
export interface UpdatePaymentModeForSalesInput {
  paymentMode?: UpdatePaymentModeInput;
  clientMutationId?: String;
}
export interface UpdatePaymentModeInput {
  salesId: ID;
  forSaleChallan: Boolean;
  date: Date;
  paymentType: String;
  amount: Float;
  balanceAmount?: Float;
  narration?: String;
}
export interface CreateValueAdditionPayload {
  newValueAddition?: ValueAdditionNode;

  clientMutationId?: String;
}
export interface CreateValueAdditionInput {
  valueAddition?: ValueAdditionInput;
  clientMutationId?: String;
}
export interface ValueAdditionInput {
  id?: ID;
  date: Date;
  customerId?: ID;
  vendorId?: ID;
  billNo?: String | null;
  branchBillNo?: String | null;
  remarks: String;
  amount: Float | null;
  type: String;
  companyId?: ID | null;
  branchId?: ID | null;
}
export interface CreateDepositPayload {
  newDeposit?: DepositNode;

  clientMutationId?: String;
}
export interface CreateDepositInput {
  deposit?: DepositInput;
  clientMutationId?: String;
}
export interface DepositInput {
  id?: ID;
  date: Date;
  depositAmount: Float | null;
  remarks?: String;
  branchId?: ID | null;
  companyId?: ID | null;
}
export interface CreateDeliveryInstructionPayload {
  newDeliveryInstruction?: DeliveryInstructionNode;

  clientMutationId?: String;
}
export interface CreateDeliveryInstructionInput {
  deliveryInstruction?: DeliveryInstructionInput;
  clientMutationId?: String;
}
export interface DeliveryInstructionInput {
  id?: ID;
  date: Date;
  deliveryInstructionNo?: String;
  customerId: ID | null;
  productDetails: ProductDetailsInput[];
  remarks?: String;
  purchaseOrderNumber?: String;
  purchaseOrderDate?: Date | null;
  layingAndFittingCharge?: Float;
  transportationCharge?: Float;
  otherCharges?: Float;
  isGstInclusive?: Boolean;
  companyId?: ID | null;
}
export interface CreateSaleChallanPayload {
  newSaleChallan?: SaleChallanNode;

  clientMutationId?: String;
}
export interface CreateSaleChallanInput {
  saleChallan?: SaleChallanInput;
  clientMutationId?: String;
}
export interface SaleChallanInput {
  id?: ID;
  date: Date;
  challanNo?: String | null;
  deliveryInstructionId?: ID | null;
  productDetails: ProductDetailsInput[];
  remarks?: String | null;
  companyId?: ID | null;
  dispatchedThrough?: String | null;
  destination?: String;
  vehicleNo?: String;
  miscellaneousCharge?: Float;
  transportationCharges?: Float;
  otherCharges?: Float;
  totalAmount: Float;
  cancelled?: Boolean;
}
export interface CreatePurchasePayload {
  newPurchase?: PurchaseNode;

  clientMutationId?: String;
}
export interface CreatePurchaseInput {
  purchase?: PurchaseInput;
  clientMutationId?: String;
}
export interface PurchaseInput {
  id?: ID;
  date: Date;
  billNo?: String;
  productDetails: ProductDetailsInput[];
  vendorId: ID;
  vendorInvoiceNo?: String;
  vendorInvoiceDate?: Date;
  purchaseOrderNo?: String;
  purchaseOrderDate?: Date;
  receivingChallanNo?: String;
  packingCharges?: Float;
  transportationCharges?: Float;
  otherCharges?: Float;
  netAmount?: Float;
  isReturn: Boolean;
  purchaseToReturnId?: ID;
  remarks?: String;
  companyId: ID;
}
export interface CreatePurchaseOrderPayload {
  newPurchaseOrder?: PurchaseOrderNode;

  clientMutationId?: String;
}
export interface CreatePurchaseOrderInput {
  purchaseOrder?: PurchaseOrderInput;
  clientMutationId?: String;
}
export interface PurchaseOrderInput {
  id?: ID;
  date: Date;
  purchaseOrderNo?: String;
  vendorId: ID | null;
  productDetails: String;
  remarks?: String | null;
  companyId?: ID | null;
  shippingAddress?: String;
  quotationDetails?: String;
}
export interface CancelPurchaseOrderInput {
  id: ID;
  clientMutationId?: String;
}
export interface CancelPurchaseOrderPayload {
  jobOrderToCancel?: PurchaseOrderNode;

  clientMutationId?: String;
}
export interface CreateChallanPayload {
  newChallan?: ChallanNode;

  clientMutationId?: String;
}
export interface CreateChallanInput {
  challan?: ChallanInput;
  clientMutationId?: String;
}
export interface ChallanInput {
  id?: ID;
  date: Date;
  challanNo?: String;
  productDetails: String;
  purchaseOrderId: ID | null;
  vendorChallanNo: String | null;
  vendorChallanDate: Date | null;
  dumpToHeadOffice: Boolean;
  dumpLocationId?: ID | null;
  remarks?: String | null;
  companyId?: ID | null;
}
export interface CreateMarginPayload {
  newMargin: MarginNode;

  clientMutationId?: String;
}
export interface CreateMarginInput {
  margin?: MarginInput;
  clientMutationId?: String;
}
export interface MarginInput {
  id?: ID;
  itemsFrom: String | null;
  challanId?: ID | null;
  productionTransferId?: ID | null;
  freight: Float;
  otherExpenses: Float;
  productDetails?: String;
  companyId?: ID | null;
}
export interface CreateUnitPayload {
  newUnit?: UnitNode;

  clientMutationId?: String;
}
export interface CreateUnitInput {
  unit?: UnitInput;
  clientMutationId?: String;
}
export interface UnitInput {
  id?: String;
  name: String;
  value: Float;
  companyId: ID;
}
export interface CreateProductCategoryPayload {
  newProductCategory?: ProductCategoryNode;

  clientMutationId?: String;
}
export interface CreateProductCategoryInput {
  productCategory?: ProductCategoryInput;
  clientMutationId?: String;
}
export interface ProductCategoryInput {
  id?: String;
  name: String;
  initials: String;
  companyId: ID;
}
export interface CreateProductPayload {
  newProduct?: ProductNode;

  clientMutationId?: String;
}
export interface CreateProductInput {
  product?: ProductInput;
  clientMutationId?: String;
}
export interface ProductInput {
  id?: String;
  categoryId: String;
  oldBarcodeId?: String;
  newBarcodeId?: String;
  name: String;
  unitId: String;
  colour?: String;
  purchaseRate: Float;
  sellingRate: Float;
  hsnCode?: String;
  hsnId?: ID;
  cgstRate?: Float;
  sgstRate?: Float;
  gstRate?: Float;
  division?: String;
  section?: String;
  materialType?: String;
  productType?: String;
  reorderLevel?: Float;
  quantityPerPiece?: Float;
  companyId: ID;
}
export interface CreateProductDetailsPayload {
  newProductDetails: ProductDetailsNode[];

  clientMutationId?: String;
}
export interface CreateProductDetailsInput {
  productDetails: ProductDetailsInput[];
  clientMutationId?: String;
}
export interface CreateContractorPayload {
  newContractor?: ContractorNode;

  clientMutationId?: String;
}
export interface CreateContractorInput {
  contractor?: ContractorInput;
  clientMutationId?: String;
}
export interface ContractorInput {
  id?: String;
  name: String;
  address?: String;
  contactNo?: String | null;
  email?: String;
  tdsRate?: Float | null;
  serviceCharge?: Float | null;
  pointBalance?: Float;
  companyId?: ID | null;
}
export interface CreateCustomerPayload {
  newCustomer?: CustomerNode;

  clientMutationId?: String;
}
export interface CreateCustomerInput {
  customer?: CustomerInput;
  clientMutationId?: String;
}
export interface CustomerInput {
  id?: String;
  name: String;
  address?: String;
  contactNo?: String;
  email?: String;
  dateOfBirth?: String;
  gstinLegalName?: String;
  gstinNumber?: String;
  dateOfAnniversary?: String;
  pointBalance?: Float;
  creditLimit?: Float;
  companyId: ID;
}
export interface CreateCompanyPayload {
  newCompany?: CompanyNode;

  clientMutationId?: String;
}
export interface CreateCompanyInput {
  company?: CompanyInput;
  clientMutationId?: String;
}
export interface CompanyInput {
  id?: ID;
  name: String;
  address?: String;
  state: String;
  contactNo?: String;
  gstin: String;
  gstRate?: Float;
  hsnCode?: String;
  amountPerPointSale: Float;
  amountPerPointRedeem: Float;
  withProduction?: Boolean;
  withAmc: Boolean;
  bankName?: String;
  branch?: String;
  accountNo?: String;
  ifscCode?: String;
}
export interface CreateBranchPayload {
  newBranch?: BranchNode;

  clientMutationId?: String;
}
export interface CreateBranchInput {
  branch?: BranchInput;
  clientMutationId?: String;
}
export interface BranchInput {
  id?: ID;
  name: String;
  address: String;
  state: String;
  contactNo: String;
  incharge?: String;
  category: String;
  parentCategory?: String;
  email?: String;
  gstin?: String;
  companyId: ID;
  parentLocationId?: ID;
}
export interface CreateUserDetailPayload {
  newUserDetails?: UserDetailNode;

  clientMutationId?: String;
}
export interface CreateUserDetailInput {
  userDetails?: UserDetailInput;
  clientMutationId?: String;
}
export interface UserDetailInput {
  userDetailId?: ID;
  userId?: ID;
  username: String;
  password: String;
  email?: String;
  firstName?: String;
  lastName?: String;
  isSuperuser: Boolean;
  groupId?: ID;
  branchId?: ID;
  companyId: ID;
}
export interface __Schema {
  types: null[];

  queryType: __Type;

  mutationType?: __Type;

  subscriptionType?: __Type;

  directives: null[];
}

export interface __Type {
  kind: __TypeKind;

  name?: String;

  description?: String;

  fields: (params: { includeDeprecated?: Boolean }) => null;

  interfaces: null[];

  possibleTypes: null[];

  enumValues: (params: { includeDeprecated?: Boolean }) => null;

  inputFields: null[];

  ofType?: __Type;
}

export enum __TypeKind {
  // Indicates this type is a scalar.
  SCALAR = 'SCALAR', // Indicates this type is an object. `fields` and `interfaces` are valid fields.
  OBJECT = 'OBJECT', // Indicates this type is an interface. `fields` and `possibleTypes` are valid fields.
  INTERFACE = 'INTERFACE', // Indicates this type is a union. `possibleTypes` is a valid field.
  UNION = 'UNION', // Indicates this type is an enum. `enumValues` is a valid field.
  ENUM = 'ENUM', // Indicates this type is an input object. `inputFields` is a valid field.
  INPUT_OBJECT = 'INPUT_OBJECT', // Indicates this type is a list. `ofType` is a valid field.
  LIST = 'LIST', // Indicates this type is a non-null. `ofType` is a valid field.
  NON_NULL = 'NON_NULL',
}

export interface __Field {
  name: String;

  description?: String;

  args: null[];

  type: __Type;

  isDeprecated: Boolean;

  deprecationReason?: String;
}

export interface __InputValue {
  name: String;

  description?: String;

  type: __Type;

  defaultValue?: String;
}

export interface __EnumValue {
  name: String;

  description?: String;

  isDeprecated: Boolean;

  deprecationReason?: String;
}

export interface __Directive {
  name: String;

  description?: String;

  locations: null[];

  args: null[];

  onOperation: Boolean;

  onFragment: Boolean;

  onField: Boolean;
}

export enum __DirectiveLocation {
  // Location adjacent to a query operation.
  QUERY = 'QUERY', // Location adjacent to a mutation operation.
  MUTATION = 'MUTATION', // Location adjacent to a subscription operation.
  SUBSCRIPTION = 'SUBSCRIPTION', // Location adjacent to a field.
  FIELD = 'FIELD', // Location adjacent to a fragment definition.
  FRAGMENT_DEFINITION = 'FRAGMENT_DEFINITION', // Location adjacent to a fragment spread.
  FRAGMENT_SPREAD = 'FRAGMENT_SPREAD', // Location adjacent to an inline fragment.
  INLINE_FRAGMENT = 'INLINE_FRAGMENT', // Location adjacent to a schema definition.
  SCHEMA = 'SCHEMA', // Location adjacent to a scalar definition.
  SCALAR = 'SCALAR', // Location adjacent to an object definition.
  OBJECT = 'OBJECT', // Location adjacent to a field definition.
  FIELD_DEFINITION = 'FIELD_DEFINITION', // Location adjacent to an argument definition.
  ARGUMENT_DEFINITION = 'ARGUMENT_DEFINITION', // Location adjacent to an interface definition.
  INTERFACE = 'INTERFACE', // Location adjacent to a union definition.
  UNION = 'UNION', // Location adjacent to an enum definition.
  ENUM = 'ENUM', // Location adjacent to an enum value definition.
  ENUM_VALUE = 'ENUM_VALUE', // Location adjacent to an input object definition.
  INPUT_OBJECT = 'INPUT_OBJECT', // Location adjacent to an input object field definition.
  INPUT_FIELD_DEFINITION = 'INPUT_FIELD_DEFINITION',
}
