import * as React from 'react';
import { Table, Spin, Skeleton, Icon, Row, Col } from 'antd';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { TransferNodeConnection, TransferNodeEdge } from '../../schema';
import { Pagination } from 'sha-el-design/lib';
import { DateService } from '../../service/DateService';
import { ColumnProps } from '../common/Table';
import { acceptedReceivingQuery } from './constants';
import { TSInput } from '../common/TSInput';

export class AcceptedReceiving extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      invoiceNo: '',
    };
  }

  companyService = new CompanyService();
  dateService = new DateService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
  }

  transferColumn = () => {
    const receivingColumns: ColumnProps<TransferNodeEdge>[] = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
      },
      {
        title: 'Transfer Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'Receiving Date',
        dataIndex: 'node.receivingDate',
        key: 'receivingDate',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'From',
        dataIndex: 'node.fromLocation.name',
        key: 'fromLocation',
        render: (value) => value || 'Head Office',
      },
    ];
    if (
      this.state.company?.name &&
      (this.state.company.name.toLowerCase().includes('pearl') ||
        this.state.company.name.toLowerCase().includes('seva sadan'))
    ) {
      receivingColumns.push({
        title: 'Receipt',
        key: 'receipt',
        render: (text: TransferNodeEdge) => (
          <a
            onClick={() =>
              this.props.handleSelectedReceiving(
                text,
                (text.node?.fromHeadOffice
                  ? this.state.company?.gstin?.slice(0, 2)
                  : (
                      text.node?.fromLocation || text.node?.vendorFor
                    )?.gstin?.slice(0, 2)) ===
                  (text.node?.toHeadOffice
                    ? this.state.company?.gstin?.slice(0, 2)
                    : (
                        text.node?.toLocation || text.node?.vendorFor
                      )?.gstin?.slice(0, 2))
                  ? '4'
                  : '5',
              )
            }
          >
            <Icon type="download" />
          </a>
        ),
      });
    }
    return receivingColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    const {
      company: { id: companyId },
      after,
      currentPage,
      business: { type: businessType, id: businessId },
    } = this.state;
    const { activeKey } = this.props;
    return (
      <div>
        <Row>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.invoiceNo}
              onChange={(e) => this.setState({ invoiceNo: e.target.value })}
              name="name"
              type="text"
              addonBefore="Search Accepted Receiving"
              placeholder="Enter Invoice No."
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={acceptedReceivingQuery}
          variables={{
            companyId,
            activeKey,
            toLocationId: businessType === 'branch' ? businessId : '',
            toHeadOffice: businessType === 'company' ? true : false,
            date_Lte: this.dateService?.date$.value,
            after,
            transferInvoice: this.state.invoiceNo,
          }}
          render={(
            response: { allTransfers: TransferNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Spin />;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allTransfers.edges}
                  columns={this.transferColumn()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allTransfers.pageInfo.endCursor,
                      response.allTransfers.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
      </div>
    );
  }
}

interface Props {
  activeKey: string;
  handleSelectedReceiving: (
    selectedTransfer: TransferNodeEdge,
    activeKey: string,
  ) => void;
}

interface State {
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  invoiceNo: string;
  currentPage: number;
}
