import * as React from 'react';
import { Table, Spin, Skeleton, Icon } from 'antd';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  ProductionTransferNodeEdge,
  ProductionTransferNodeConnection,
} from '../../../schema';
import { Pagination } from 'sha-el-design/lib';
import { DateService } from '../../../service/DateService';
import { ColumnProps } from '../../common/Table';
import { acceptedProductionReceivingQuery } from './constants';
import { ProductionTransferService } from '../../../service/ProductionTransferService';

export class AcceptedProductionReceiving extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      business: null,
      company: null,
      after: '',
      currentPage: 1,
    };
  }

  transferService = new ProductionTransferService();
  companyService = new CompanyService();
  dateService = new DateService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
  }

  transferColumn = () => {
    const receivingColumns: ColumnProps<ProductionTransferNodeEdge>[] = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
      },
      {
        title: 'Receiving Invoice No',
        dataIndex: 'node.receivingInvoiceNo',
        key: 'receivingInvoiceNo',
      },
      {
        title: 'Transfer Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'Receiving Date',
        dataIndex: 'node.receivingDate',
        key: 'receivingDate',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'From',
        dataIndex: 'node.fromBranch.name',
        key: 'fromBranch',
        render: (text, record) => text || record?.node?.vendorFor?.name,
      },
    ];
    if (
      this.state.company &&
      (this.state.company.name.toLowerCase().includes('pearl') ||
        this.state.company.name.toLowerCase().includes('seva sadan'))
    ) {
      receivingColumns.push({
        title: 'Receipt',
        key: 'receipt',
        render: (text: ProductionTransferNodeEdge) => (
          <a
            onClick={() =>
              this.props.handleSelectedReceiving(
                text,
                (text.node?.fromBranch || text.node?.vendorFor)?.gstin?.slice(
                  0,
                  2,
                ) ===
                  (text.node?.toHeadOffice
                    ? this.state.company?.gstin?.slice(0, 2)
                    : (
                        text.node?.toBranch || text.node?.vendorFor
                      )?.gstin?.slice(0, 2))
                  ? '4'
                  : '5',
              )
            }
          >
            <Icon type="download" />
          </a>
        ),
      });
    }
    return receivingColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    const {
      company: { id: companyId },
      after,
      currentPage,
      business: { type: businessType, id: businessId },
    } = this.state;
    const { activeKey } = this.props;
    return (
      <GraphqlQuery
        queryString={acceptedProductionReceivingQuery}
        variables={{
          companyId,
          activeKey,
          toBranchId: businessType === 'branch' ? businessId : '',
          toHeadOffice: businessType === 'company' ? true : false,
          date_Lte: this.dateService?.date$.value,
          after,
        }}
        render={(
          response: {
            allProductionTransfers: ProductionTransferNodeConnection;
          },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }
          if (response && companyId) {
            return [
              <Table
                key="table"
                dataSource={response.allProductionTransfers.edges}
                columns={this.transferColumn()}
                rowKey={(obj) => obj.node?.id || ''}
                pagination={false}
              />,
              <Pagination
                key="pagination"
                totalCount={0}
                currentPage={currentPage}
                batchSize={20}
                cursorBasedPagination
                onChange={(_p, _ps, next) =>
                  this.onPageChange(
                    next,
                    response.allProductionTransfers.pageInfo.endCursor,
                    response.allProductionTransfers.pageInfo.hasNextPage,
                  )
                }
                style={{ float: 'right' }}
              />,
            ];
          }
          return <Skeleton active />;
        }}
      />
    );
  }
}

interface Props {
  activeKey: string;
  handleSelectedReceiving: (
    selectedTransfer: ProductionTransferNodeEdge,
    activeKey: string,
  ) => void;
}

interface State {
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
}
